<template>
  <div :class="containerClass">

    <b-row>
      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('admin.clubs.config.white-mark-name') }}
        </label>
        <d-text-field
            v-model="config.name"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>
      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('admin.clubs.config.sms-name') }}
        </label>
        <d-text-field
            v-model="config.smsSenderName"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>

      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('admin.clubs.config.branchio-api-key') }}
        </label>
        <d-text-field
            v-model="config.branchApiKey"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>

      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('admin.clubs.config.onesignal-api-key') }}
        </label>
        <d-text-field
            v-model="config.oneSignalApiAuthKey"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>
      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('admin.clubs.config.onesignal-api-id') }}
        </label>
        <d-text-field
            v-model="config.oneSignalApiId"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>

      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('admin.clubs.config.app-version') }}
        </label>
        <d-text-field
            v-model="config.appVersion"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>
      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('admin.clubs.config.app-apple-store-link') }}
        </label>
        <d-text-field
            v-model="config.appleAppLink"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>

      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('admin.clubs.config.app-google-store-link') }}
        </label>
        <d-text-field
            v-model="config.googleAppLink"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3" class="mt-2 mb-2">
        <div class="d-btn-small btn text-center d-btn-primary btn-full-width" v-on:click="sendFormConfig()">
          {{ config.id?$t('general.actions.update'):$t('general.actions.validate') }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { getAll, addUserClub } from "@api/services/user-club/user-club.api";
import countries from "@data/countries.json";
import {addConfigMbClub, getConfigMbClub, postStripeClub, putClub, upConfigMbClub} from "@api/services/club/club.api";

export default {
  data: () => ({
    countries: countries,
    members: [],
    timer: null,
    showAddForm: false,
    isBusy: true,
    itemsPerPage: 20,
    totalRows: 0,
    currentPage: 1,
    items: [],
  }),
  props: {
    club: {
      type: Object,
      default: '',
    },
    config: {
      type: Object,
      default: {},
    },
    family: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
  },
  methods: {
    sendFormConfig() {
      if (this.config.id) {
        upConfigMbClub(this.config['@id'], this.config).then(res => {
          if (res) {
            this.$vs.notify({
              time: 10000,
              title: this.$t('admin.clubs_config.notify.success_create.title'),
              text: this.$t('admin.clubs_config.notify.success_create.message'),
              color: 'success',position: 'top-right'
            });
            this.$emit('update-config', res);
          }
        });
      } else {
        addConfigMbClub(this.config).then(res => {
          if (res) {
            putClub(this.club.id, {whiteLabel: res.data['@id']}).then(resp => {
              if (resp) {
                this.$vs.notify({
                  time: 10000,
                  title: this.$t('admin.clubs_config.notify.success_create.title'),
                  text: this.$t('admin.clubs_config.notify.success_create.message'),
                  color: 'success',position: 'top-right'
                });
                this.$emit('updateConfig', res);
              }
            });
          }
        });
      }
    }
  }
}
</script>

<style scoped>
.groups-label {
  font: normal normal 300 17px Avenir;
  letter-spacing: 0px;
  color: #272727;
  opacity: 1;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
</style>
